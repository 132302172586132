<template>
  <b-nav-item-dropdown
    variant="primary"
    right
    class="shopCart"
    @toggle="$root.$emit('bv::hide::popover')"
  >
    <!--    Icon    -->
    <template #button-content>
      <feather-icon
        size="20"
        icon="ShoppingCartIcon"
        class="text-black"
        badge-classes="badge-success"
        :badge="products.length"
      />
    </template>

    <!--    Cart Header    -->
    <b-dropdown-header>
      <h4 class="d-flex justify-content-between">
        <div class="pr-1">
          {{ $t('OfferBasket') }}
        </div>
        <div
          v-b-tooltip
          class="text-muted text-truncate"
          :title="cart.name"
        >
          {{ cart.name || '-' }}
        </div>
      </h4>
      <div
        v-if="thread && thread.name"
        class="text-truncate pb-25 d-flex flex-column"
      >
        <span class="mb-25">
          {{ thread.contact.firstName }} {{ thread.contact.lastName }}
        </span>

        <router-link :class="{ 'deleted-text': thread.contact.deletedAt }" :to="`/contact/${thread.contact.id}/thread/${thread.id}`">
          {{ thread.name | truncate(34, '…') }}
        </router-link>
      </div>
    </b-dropdown-header>

    <!--    Cart Items    -->
    <vue-perfect-scrollbar
      v-if="products.length > 0"
      :settings="perfectScrollbarSettings"
      class="shopCartItem"
      style="height: 255px"
    >
      <b-dropdown-text
        v-for="(item, index) in products"
        :key="item.id"
        class="position-relative d-flex justify-content-center"
      >
        <product
          :product="item"
          @remove-product="removeProduct($event, index)"
        />
      </b-dropdown-text>
    </vue-perfect-scrollbar>

    <div
      v-else
      class="shopCartItem text-center py-2"
    >
      <h3 class="emptyCart">
        {{ $t('YourCartIsEmpty') }}
      </h3>
      <b-button
        variant="flat-primary"
        size="sm"
        :to="{ name: 'shop' }"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('offer.product.new') }}
      </b-button>
    </div>

    <!--    Cart Calculation Bar    -->
    <b-dropdown-text
      class="
        calculationBar
        bg-white
        bg-lighten-5
        card
        mb-0
        border-top
        rounded-top
      "
    >
      <div
        class="
          d-flex
          justify-content-between
          align-content-center
          w-100
        "
      >
        <h6>{{ $t('Offer.Total') }}:</h6>
        <h5 class="font-weight-light">
          {{ totalProductsGrossPrice | priceGrossFormat }} PLN
        </h5>
      </div>
      <b-button
        variant="outline-primary"
        class="w-100 mt-25"
        :to="{ name: 'shop/checkout' }"
        :disabled="!products.length"
      >
        {{ $t('Offer.FinishYourOrder') }}
      </b-button>
    </b-dropdown-text>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownHeader, BDropdownText, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { GET_USER_CART, REMOVE_PRODUCT_FROM_CART } from '@/@constants/mutations'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { grossPrice, netPriceAfterCartDiscount, netPriceByAmount } from '@/helpers/cartProductPrices'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'
import product from './CardProduct.vue'

export default {
  components: {
    VuePerfectScrollbar,
    BNavItemDropdown,
    BDropdownHeader,
    BDropdownText,
    product,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
    },
  }),
  mounted() {
    if (this.checkRequiredModule('offerModule')) {
      this.$store.dispatch(`cart/${GET_USER_CART}`)
    }
  },
  computed: {
    ...mapGetters({
      products: 'cart/getCartProducts',
      cart: 'cart/getCart',
      thread: 'cart/getCartThread',
    }),
    totalProductsGrossPrice() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b)), 0) : 0
    },
  },
  methods: {
    priceGrossFormat,
    netPriceByAmount,
    netPriceAfterCartDiscount,
    grossPrice,
    async removeProduct(productId, index) {
      try {
        await this.$store.dispatch(`cart/${REMOVE_PRODUCT_FROM_CART}`, { productId })

        this.products.slice(index, 1)
        this.showToast('success', this.$i18n.t('ProductRemovedFromCard'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<style lang="scss">
/*    Separator    */
.separator {
  width : 100%;
  height: 2px;

  margin: 0;

  border-width: 0;

  color: var(--bg-shopItem);
  background-color: var(--bg-shopItem);

  opacity: .25;
}

/*    Shop Cart    */
.shopCart {
  /*    Drop Down Header    */
  .dropdown-header {
    width: 30rem;
  }

  .emptyCart { opacity: .35 }

  .dropdown-header { max-width: 100% }

  /*    Li    */
  li { list-style: none }

  /*    Drop Down Menu    */
  .dropdown-menu {
    padding-top   : 1rem;
    padding-bottom: unset;
  }

  /*    Cart Item    */
  .shopCartItem {
    max-height: 24rem;

    padding: .74rem 0;

    overflow: scroll;

    p { padding: unset; }

    .row { padding: 1rem 0; }

    .deleteFromCart {
      top: -.75rem;
      right: 1rem;

      color     : var(--grey);
      background: var(--white);

      border: 1px solid var(--gray);
    }

    li:hover {
      background: var(--bg-shopItem-lighten-4);

      .deleteFromCart { display: inline !important; }
    }
  }

  /*    Cart Calculation Bar    */
  .calculationBar {
    padding-top   : .64rem;
    padding-bottom: .5rem;

    p {
      display       : flex;
      flex-direction: column;
      align-items   : center;
    }
  }
}
</style>
