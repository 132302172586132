<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <sw-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <ul class="nav navbar-nav d-flex align-items-center">
      <li class="nav-item mr-75 cursor-pointer d-none d-xl-block"
          @click="isVerticalMenuCollapsed = !isVerticalMenuCollapsed"
      >
        <sw-icon :icon="!isVerticalMenuCollapsed ? 'DiscIcon' : 'CircleIcon'"
                 size="20"
        />
      </li>
      <search-bar />
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      style="list-style: none"
    />

    <b-navbar-nav
      class="nav align-items-center ml-auto"
      style="column-gap: .85rem"
    >
      <b-badge v-b-tooltip.html
               :variant="getWalletStateColor"
               :title="isWalletCloseToStateEnd ? $t('WallerState') + ': ' + Number(globalInfoSubscriptionData.wallet).toFixed(2) : getEstimatedValues"
               :class="{ 'cursor-pointer': isWalletCloseToStateEnd }"
               @click="redirectToPanel"
      >
        <span v-if="!isMobile">{{ Number(globalInfoSubscriptionData.wallet).toFixed(2) }}</span>
        <sw-icon :icon="isWalletCloseToStateEnd ? 'PlusIcon' : 'HexagonIcon'" />
      </b-badge>

      <sw-icon
        v-if="checkRequiredModule('shortcutModule') && checkRequiredPermissions([$roles.CONTACT_ADD]) && checkRequiredViewPermissions([$viewRanks.SHOW_UPPER_HEADER_CONTACT_ADD])"
        v-b-tooltip
        class="cursor-pointer"
        icon="BookIcon"
        size="20"
        :title="$t('ContactGenerator')"
        @click="openModal(modalList.CONTACT_SHORT)"
      />

      <sw-icon
        v-if="checkRequiredPermissions([$roles.CONTACT_ADD]) && checkRequiredViewPermissions([$viewRanks.SHOW_UPPER_HEADER_CONTACT_ADD])"
        v-b-tooltip
        class="cursor-pointer d-none d-md-inline-block "
        icon="UserPlusIcon"
        size="20"
        :title="$t('NewContact')"
        @click="$router.push({ name: 'new-contacts' })"
      />

      <sw-icon
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_NOTES], [$viewRanks.SHOW_UPPER_HEADER_CONTACT_NOTE]) && checkRequiredPermissions([$roles.CONTACT_THREAD_NOTE_VIEW_ALL, $roles.CONTACT_THREAD_NOTE_VIEW_SELF, $roles.CONTACT_THREAD_NOTE_VIEW_SHARED], [
          $roles.CONTACT_THREAD_NOTE_ADD,
        ])"
        v-b-tooltip
        class="cursor-pointer d-none d-md-inline-block "
        icon="FileIcon"
        size="20"
        :title="$t('NewNote')"
        @click="openModal(modalList.NOTE)"
      />

      <sw-icon
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS], [$viewRanks.SHOW_UPPER_HEADER_CONTACT_TASK]) && checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE ], [
          $roles.CONTACT_THREAD_TASK_ADD,
        ])"
        v-b-tooltip
        class="cursor-pointer d-none d-md-inline-block "
        icon="ListIcon"
        size="20"
        :title="$t('NewTask')"
        @click="openModal(modalList.TASK)"
      />
      <span
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS], [$viewRanks.SHOW_UPPER_HEADER_CONTACT_TASK]) && checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE ])"
        v-b-tooltip
        :title="$t('Calendar')"
      >
        <sw-icon
          class="cursor-pointer d-none d-md-inline-block"
          :class="{ 'text-muted': $route.name === 'calendar' }"
          icon="CalendarIcon"
          size="20"
          @click="$route.name === 'calendar' ? '' : $router.push({ 'name': 'calendar' })"
        />
      </span>

      <sw-icon
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS], [$viewRanks.SHOW_UPPER_HEADER_CONTACT_EMAIL]) && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF],[$roles.CONTACT_THREAD_SMS_ADD])"
        v-b-tooltip
        class="cursor-pointer d-none d-md-inline-block "
        icon="PhoneIcon"
        size="20"
        :title="$t('SendSms')"
        @click="openModal(modalList.SMS)"
      />

      <sw-icon
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS], [$viewRanks.SHOW_UPPER_HEADER_CONTACT_SMS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
        v-b-tooltip
        class="cursor-pointer d-none d-md-inline-block "
        icon="MailIcon"
        size="20"
        :title="$t('SendEmail')"
        @click="openModal(modalList.EMAIL)"
      />

      <notification-dropdown class="mx-n50" />

      <!--    The Shop Cart    -->
      <the-cart
        v-if="checkRequiredModule('offerModule') && checkRequiredPermissions([$roles.SHOP_PRODUCT_VIEW_ALL, $roles.SHOP_PRODUCT_VIEW_GROUP]) && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
        class="mx-n25"
      />

      <!--      <div-->
      <!--        class="position-relative"-->
      <!--        :class="{ 'disabled-cross': $store.getters['app/getShowHelpTooltips'] }"-->
      <!--      >-->
      <!--        <sw-icon-->
      <!--          v-b-tooltip-->
      <!--          class="cursor-pointer d-none d-md-inline-block"-->
      <!--          icon="HelpCircleIcon"-->
      <!--          size="20"-->
      <!--          :title="$t('HelpInformations')"-->
      <!--          @click="$store.commit('app/TOGGLE_HELP_TOOLTIPS')"-->
      <!--        />-->
      <!--      </div>-->

      <dark-toggler />

      <locale class="mx-n50" />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-n1 py-25"
        @toggle="$root.$emit('bv::hide::popover')"
      >
        <template #button-content>
          <!--          <div class="d-md-flex d-none user-nav">-->
          <!--            <p class="user-name font-weight-bolder mb-0">-->
          <!--              {{ user.firstName }} {{ user.lastName }}-->
          <!--            </p>-->
          <!--            <span class="user-status">{{ user.email }}</span>-->
          <!--          </div>-->
          <avatar
            size="34"
            variant="light-primary"
            :user="user"
            :show-name="false"
          />
        </template>
        <!--        <b-dropdown-item-->
        <!--          v-if="checkRequiredPermissions([$roles.ACTUALIZATIONS])"-->
        <!--          link-class="d-flex align-items-center"-->
        <!--          :to="{ name: 'change-logs' }"-->
        <!--        >-->
        <!--          <sw-icon-->
        <!--            size="16"-->
        <!--            icon="ToolIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>{{ $t('Updates') }}</span>-->
        <!--        </b-dropdown-item>-->
        <b-dropdown-item
          v-if="checkRequiredPermissions([$roles.FULL_ACCESS])"
          link-class="d-flex align-items-center"
          :to="{ name: 'app-module' }"
        >
          <sw-icon
            size="16"
            icon="BoxIcon"
            class="mr-50"
          />
          <span>{{ $t('Modules') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'user-sessions' }"
        >
          <sw-icon
            size="16"
            icon="BookIcon"
            class="mr-50"
          />
          <span>{{ $t('History') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'user-profile' }"
        >
          <sw-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>
        <!--        <b-dropdown-item-->
        <!--          link-class="d-flex align-items-center"-->
        <!--          @click="showModalQR"-->
        <!--        >-->
        <!--          <sw-icon-->
        <!--            size="16"-->
        <!--            icon="SmartphoneIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>{{ $t('App') }}</span>-->
        <!--        </b-dropdown-item>-->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <sw-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable */

import { BDropdownItem, BLink, BNavbarNav, BNavItemDropdown, VBTooltip, } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import { mapGetters } from 'vuex'
import theCart from '@/views/components/topBar/Cart.vue'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import {computed, h, onUnmounted} from '@vue/composition-api'
import Locale from '../../@core/layouts/components/app-navbar/components/Locale.vue'
import NotificationDropdown from '../../@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { ADD_MODAL, AUTH_LOGOUT, OPEN_MODAL, SHOW_MODAL } from '../../@constants/mutations'
import useVerticalLayout from "@core/layouts/layout-vertical/useVerticalLayout";
import {checkRequiredHostnameFragments} from "@core/mixins";

export default {
  name: 'SwNavbar',
  components: {
    Locale,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    theCart,
    SearchBar,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    modalList: {
      TASK: {
        type: 'task',
        title: 'AddTask',
        button: 'AddTask',
      },

      CONTACT_SHORT: {
        type: 'contactShort',
      },

      NOTE: {
        type: 'note',
        title: 'AddNote',
        button: 'AddNote',
      },

      SMS: {
        type: 'sms',
        title: 'Sms',
        button: 'Send',
      },

      EMAIL: {
        type: 'mail',
        title: 'Email',
        button: 'Send',
      },
    },

    mQrCode: '',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      globalInfoSubscriptionData: 'verticalMenu/globalInfoSubscriptionData',
    }),
    getEstimatedValues() {
        // globalInfoSubscriptionData.estimatedFees
      return `
        <div>${this.$i18n.t('WallerStateNeedToBeLoaded')}</div>
        <div class="d-flex justify-content-between">
          ${this.$i18n.t('PriceForOneVariant')} 10 Sms:
          <span>${this.globalInfoSubscriptionData.estimatedFees.sms_per_sms * 10} PLN</span>
        </div>

        <div class="d-flex justify-content-between">
          ${this.$i18n.t('PriceForOneVariant')} 10 Email:
          <span>${this.globalInfoSubscriptionData.estimatedFees.mail_per_mail * 10} PLN</span>
        </div>
      `
    },
    getWalletStateColor() {
      if (this.globalInfoSubscriptionData.wallet < 5) return 'danger'
      if (this.globalInfoSubscriptionData.wallet < 10) return 'light-danger'

      return 'warning'
    },
    isWalletCloseToStateEnd() {
      if (this.globalInfoSubscriptionData.wallet < 10) return true

        return false
    },
  },
  mounted() {
    this.createModals()
  },
  methods: {
    checkRequiredHostnameFragments,
    logout() {
      this.$store.dispatch(`auth/${AUTH_LOGOUT}`)
        .then(() => {
          this.showToast('success', this.$i18n.t('LoggedSuccessfully'), this.$i18n.t('alert.Success'))
          this.$router.push('/login')
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.$router.push('/login')
        })
    },
    openModal(modalItem, size = 'lg') {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
          {
            modalType: modalItem.type,
            modalTitle: this.$i18n.t(modalItem.title),
            okButtonTitle: this.$i18n.t(modalItem.button),
            size,
          })
    },
    createModals() {
      this.$store.dispatch(`swModal/${ADD_MODAL}`, {
        title: this.$i18n.t('MobileApp'),
        component: 'm-sign-in-qr-code',
        data: { content: this.singleContact },
        hideFooter: true,
      }).then(id => this.$set(this, 'mQrCode', id))
    },

    showModalQR() {
      const { mQrCode } = this

      if (mQrCode) this.$store.dispatch(`swModal/${SHOW_MODAL}`, mQrCode)
    },

    redirectToPanel() {
      if (this.isWalletCloseToStateEnd) {
          window.open('https://panel.4b-systems.com/', '_blank')
      }
    }
  },
  setup(props) {
      const { isMobile, resizeHandler } = useVerticalLayout()

      const perfectScrollbarSettings = {
          maxScrollbarLength: 60,
          wheelPropagation: false,
      }

      resizeHandler()
      window.addEventListener('resize', resizeHandler)
      onUnmounted(() => {
          window.removeEventListener('resize', resizeHandler)
      })

    const {
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
    } = useVerticalNavMenu(props)

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    return {
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      collapseTogglerIconFeather,
        isMobile,
    }
  },
}
</script>

<style lang="scss">
.disabled-cross {
  &::after {
    content: '';
    width: calc(100% + 6px);
    transform: translate(-50%, -50%) rotate(45deg);
    height: 2px;
    background-color: #333;

    position: absolute;
    left: 50%;
    top: calc(50% + 1px);
  }
}
</style>
